import React, { useState } from 'react';
import { useNavigate, useParams , useSearchParams } from 'react-router-dom';
import axios from 'axios';
import { MdCancel } from "react-icons/md";
function MessagesPage({ categories, addMessage , role ,deleteMessage , back , di }) {
  const { title } = useParams();
  const [searchParams] = useSearchParams()
  const id = searchParams.get("id")

  const category = categories.find(cat => cat.title === title);

  const [message, setMessage] = useState('');
 const navigate = useNavigate()
  const handleSubmit = (e) => {
    e.preventDefault();
    if (message.trim() === '') return;
    if(message !== ""){
     
      
    axios.post("https://crm.fenefx.net/api/quick/"+category.id+"/text" , {text: message}).then(res=>{
        addMessage(title, res.data);
        setMessage('');
        console.log(res.data);
      }).catch(err=>{
        console.log(err);
        
      })
    }
  };

  if (!category) {
    return <h2>Category not found</h2>;
  }

  return (
    <div>
      <div style={{padding:"10px" , background:"green",cursor:"pointer" , color:"white",width:"200px"}} onClick={()=>{
       back(di)
       navigate("/")
      }}> Back To Categories </div>
      <h2>{category.title}</h2>
      <ul>
        {category.texts.map((msg, index) => (
          <li style={{display:"flex" , justifyContent:"space-between"}}  className='liStyle' key={index}> {role === "admin"?<MdCancel  
          style={{verticalAlign:"middle" , fontSize:"25px" ,
             marginRight:"10px" , width:"25px"}}
          onClick={()=>{
            
            axios.post("https://crm.fenefx.net/api/quick/"+msg.id+"/text/delete" , {}).then(res=>{
              console.log(res.data);
              deleteMessage(  title   ,  msg)
              setMessage(msg.text)
            }).catch(err=>{
              console.log(err);
              
            })
          
          }}
          /> :null}
         <div onClick={()=>{
          navigator.clipboard.writeText(msg.text)
         }} style={{alignContent:"center" , direction:"rtl", textAlign:"right",width:"97%"}}> {msg.text} </div> 
          
          </li>
        ))}
      </ul>
      {role === "admin"?<form onSubmit={handleSubmit}>
        <div style={{display:"grid"}}>
        <textarea
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="پیام خود را وارد نمایید"
          style={{height:"200px", marginBottom:"10px" , direction:"rtl",textAlign:"right"}}
        />
        <button type="submit">Add Message</button>
        </div>
      </form>:null}
    </div>
  );
}

export default MessagesPage;
