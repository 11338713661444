/* eslint-disable array-callback-return */
import React, { useState , useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CategoryList from './CategoryList';
import MessagesPage from './MessagePage';
import axios from 'axios';
import "./style.css"
function App() {
  const [categories, setCategories] = useState([]);
  const [permission, setPermit] = useState([true,"user"]);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [ip, setIp] = useState('');
  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setIp(response.data.ip);
        console.log(response.data.ip);
        
      } catch (error) {
        console.error('Error fetching IP address:', error);
      }
    };

    fetchIP();
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    // Example of basic validation
    if (!email || !password) {
      setError('Both fields are required.');
      return;

    }else{
      if((email === "admin" && password === process.env.REACT_APP_ADMIN)&&(ip==="2.186.114.197"||
        ip==='2a01:4f9:c010:15b9:c212::1'||ip==="65.109.217.159")){
        setPermit([false , "admin"])
      }else if(email === "user" && password === process.env.REACT_APP_USER){
        setPermit([false , "user"])
      }
    }
    
  };
  // const url = "http://192.168.200.174:8000/"
  // const url2 = "https://crm.fenefx.net/"
  const [di,setDi] = useState(0)

  useEffect(()=>{
    axios.get("https://crm.fenefx.net/api/quick").then(res=>{
      
      setCategories(res.data)
    }).catch(err=>{
      console.log(err);
      
    })
  },[])
  const addCategory = (name ) => {
    setCategories([...categories, name]);
  };
  const catUpdater = ()=>{
    axios.get("https://crm.fenefx.net/api/quick").then(res=>{
      
      setCategories(res.data)
    }).catch(err=>{
      console.log(err);
      
    })
  }
  const addMessage = (categoryName, message) => {
    let updatedCat = categories.filter(category => category.title !== categoryName)
    let newCat = categories.find(e=>e.title === categoryName)
    let newTexts = {...newCat, texts: [...newCat.texts, message]}
    let last = [...updatedCat , newTexts]
    setCategories(last);
  };
  const deleteMessage = (categoryName, message) => {
    
    categories.map(ct=>{
      if(ct.title === categoryName){
        var array = ct.texts; // make a separate copy of the array
         var index = array.indexOf(message)
         console.log(array , index);
         
         if (index !== -1) {
          
         
           setCategories([...categories,{ ...ct, texts:array.splice(index, 1)}]) 
             }
      }
    })
   
  };
  const back = (d)=>{
    setDi(d)
  }
 const login = ()=>{
  if(permission[0]){
   return <div style={{ maxWidth: '400px', margin: 'auto', padding: '1rem' }}>
   <h2>Login</h2>
   {error && <p style={{ color: 'red' }}>{error}</p>}
   <form onSubmit={handleSubmit}>
     <div style={{ marginBottom: '1rem' }}>
       <label htmlFor="email">Username:</label>
       <input
         type="text"
         id="email"
         value={email}
         onChange={(e) => setEmail(e.target.value)}
         required
         style={{ width: '100%', padding: '0.5rem' }}
       />
     </div>
     <div style={{ marginBottom: '1rem' }}>
       <label htmlFor="password">Password:</label>
       <input
         type="password"
         id="password"
         value={password}
         onChange={(e) => setPassword(e.target.value)}
         required
         style={{ width: '100%', padding: '0.5rem' }}
       />
     </div>
     <button type="submit" style={{ padding: '0.5rem 1rem' }}>
       Login
     </button>
   </form>
 </div>
  }else{
   return <Router>
   <div>
     <Routes>
       <Route 
         path="/" 
         element={<CategoryList catUp={catUpdater} di={di}
         back={back} categories={categories} addCategory={addCategory} role={permission[1]} />} 
       />
       <Route 
         path="/category/:title" 
         element={
         <MessagesPage di={di}
         back={back}  
         categories={categories} 
         addMessage={addMessage}
          role={permission[1]} deleteMessage={deleteMessage} />} 
       />
     </Routes>
   </div>
 </Router>
  }
 }
 
  return (
    <>
     {login()}
    </>
  );
}

export default App;
